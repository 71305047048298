export interface TranscodingConfig {
  height: number;
  width: number;
  fps: number;
  bitrate: number;
}

// Export the configurations
export const TRANSCODING_CONFIG_1080P_30FPS: TranscodingConfig = {
  height: 1920,
  width: 1080,
  fps: 30,
  bitrate: 6300,
};

export const TRANSCODING_CONFIG_1080P_60FPS: TranscodingConfig = {
  height: 1920,
  width: 1080,
  fps: 60,
  bitrate: 8500,
};

export const TRANSCODING_CONFIG_720P_30FPS: TranscodingConfig = {
  height: 1280,
  width: 720,
  fps: 30,
  bitrate: 3420,
};

export const TRANSCODING_CONFIG_720P_60FPS: TranscodingConfig = {
  height: 720,
  width: 1280,
  fps: 60,
  bitrate: 4500,
};

export const transcodingPreset: Record<string, TranscodingConfig> = {
  '1080p30fps': TRANSCODING_CONFIG_1080P_30FPS,
  '1080p60fps': TRANSCODING_CONFIG_1080P_60FPS,
  '720p30fps': TRANSCODING_CONFIG_720P_30FPS,
  '720p60fps': TRANSCODING_CONFIG_720P_60FPS,
};
